
import { computed, defineComponent } from "vue";
import UiInput from "@/components/UI/Input";
import Upload from "@/components/UI/Upload";
export default defineComponent({
  name: "Form",
  components: {
    UiInput,
    Upload,
  },
  props: {
    title: {
      type: String,
      default: "",
    },

    content: {
      type: String,
      default: "",
    },
    file: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, ctx) {
    const nativeTitle = computed(() => {
      return props.title;
    });
    const nativeContent = computed(() => {
      return props.content;
    });
    const nativeFile = computed(() => {
      return props.file;
    });
    function onChangeFile(e: any) {
      console.log(e);
      ctx.emit("update:file", e);
    }
    function handleInput(e: string) {
      ctx.emit("update:title", e);
    }
    function handleContentInput(e: string) {
      ctx.emit("update:content", e);
    }
    return {
      onChangeFile,
      handleInput,
      handleContentInput,
      nativeTitle,
      nativeContent,
      nativeFile,
    };
  },
});
