<template>
  <div class="input">
    <ui-input
      placeholder="输入标题..."
      v-model="nativeTitle"
      :border="false"
      @inputchange="handleInput"
      textAlign="left"
    ></ui-input>
  </div>
  <div class="input textarea">
    <ui-input
      type="textarea"
      @inputchange="handleContentInput"
      :border="false"
      textAlign="left"
      v-model="nativeContent"
      placeholder="这一刻的想法..."
    ></ui-input>
  </div>
  <div class="upload">
    <upload v-model="nativeFile" @filechange="onChangeFile"></upload>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import UiInput from "@/components/UI/Input";
import Upload from "@/components/UI/Upload";
export default defineComponent({
  name: "Form",
  components: {
    UiInput,
    Upload,
  },
  props: {
    title: {
      type: String,
      default: "",
    },

    content: {
      type: String,
      default: "",
    },
    file: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, ctx) {
    const nativeTitle = computed(() => {
      return props.title;
    });
    const nativeContent = computed(() => {
      return props.content;
    });
    const nativeFile = computed(() => {
      return props.file;
    });
    function onChangeFile(e: any) {
      console.log(e);
      ctx.emit("update:file", e);
    }
    function handleInput(e: string) {
      ctx.emit("update:title", e);
    }
    function handleContentInput(e: string) {
      ctx.emit("update:content", e);
    }
    return {
      onChangeFile,
      handleInput,
      handleContentInput,
      nativeTitle,
      nativeContent,
      nativeFile,
    };
  },
});
</script>

<style lang="scss" scoped>
.input {
  width: 355px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #f6f6f6;
  padding: 0 13px;
  font-size: 14px;
  color: #444444;
  border: none;
  margin-bottom: 10px;
}
.input input {
  width: 100%;
  height: 100%;
  vertical-align: top;
  background-color: transparent;
  outline: none;
  border: none;
}
.upload {
  width: 355px;
  margin: 0 auto;
}
.textarea {
  margin-top: 30px;
}
</style>